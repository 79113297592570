import React from 'react';
import { isEmpty } from 'lodash-es';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import Authorized from './Authorized';
import { HasPermissionFunction } from './permissionChecks';
import { useAuth } from '../features/Auth/auth-context';
import { SIGN_IN } from '../constants/routes';

type AuthRouteProps = {
  hasPermissionFn?: HasPermissionFunction;
} & RouteProps;

const AuthRoute: React.FC<AuthRouteProps> = ({ hasPermissionFn, ...rest }) => {
  const { user } = useAuth();
  if (isEmpty(user)) {
    return <Route render={(routeProps) => <Redirect to={{ pathname: SIGN_IN, state: { from: routeProps.location } }} />} {...rest} />;
  }
  if (hasPermissionFn) {
    return (
      <Authorized hasPermissionFn={hasPermissionFn}>
        <Route {...rest} />
      </Authorized>
    );
  }
  return <Route {...rest} />;
};

export default AuthRoute;
