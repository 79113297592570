import * as Types from '../../../types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type FirmendatenSelectListQueryVariables = {};

export type FirmendatenSelectListQuery = {
  getFirmendatenList: {
    data: Array<{ firmendatenId: string; kurzBezeichnung: string; status: { value: string } }>;
    warningList: Array<{ message?: Types.Maybe<string>; type?: Types.Maybe<string> }>;
    errorList: Array<{ message?: Types.Maybe<string>; type?: Types.Maybe<string> }>;
  };
};

export type FirmendatenSelectListVariables = FirmendatenSelectListQueryVariables;
export type FirmendatenSelectListGetFirmendatenList = FirmendatenSelectListQuery['getFirmendatenList'];
export type FirmendatenSelectListData = NonNullable<FirmendatenSelectListQuery['getFirmendatenList']['data'][0]>;
export type FirmendatenSelectListStatus = NonNullable<FirmendatenSelectListQuery['getFirmendatenList']['data'][0]>['status'];
export type FirmendatenSelectListWarningList = NonNullable<FirmendatenSelectListQuery['getFirmendatenList']['warningList'][0]>;
export type FirmendatenSelectListErrorList = NonNullable<FirmendatenSelectListQuery['getFirmendatenList']['errorList'][0]>;
export const useFirmendatenSelectList = useFirmendatenSelectListQuery;

export const FirmendatenSelectListDocument = gql`
  query FirmendatenSelectList {
    getFirmendatenList {
      data {
        firmendatenId
        kurzBezeichnung
        status {
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFirmendatenSelectListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<FirmendatenSelectListQuery, FirmendatenSelectListQueryVariables>
) {
  return ApolloReactHooks.useQuery<FirmendatenSelectListQuery, FirmendatenSelectListQueryVariables>(FirmendatenSelectListDocument, baseOptions);
}
export function useFirmendatenSelectListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FirmendatenSelectListQuery, FirmendatenSelectListQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<FirmendatenSelectListQuery, FirmendatenSelectListQueryVariables>(FirmendatenSelectListDocument, baseOptions);
}
export type FirmendatenSelectListQueryHookResult = ReturnType<typeof useFirmendatenSelectListQuery>;
export type FirmendatenSelectListLazyQueryHookResult = ReturnType<typeof useFirmendatenSelectListLazyQuery>;
export type FirmendatenSelectListQueryResult = ApolloReactCommon.QueryResult<FirmendatenSelectListQuery, FirmendatenSelectListQueryVariables>;
