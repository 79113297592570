import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Drawer } from 'baseui/drawer';
import { Button } from 'baseui/button';
import { Navigation } from 'baseui/side-navigation';
import { Block } from 'baseui/block';
import { useStyletron } from 'baseui';
import { StyledSpinnerNext } from 'baseui/spinner';
import * as ROUTES from '../../constants/routes';
import { useAuth } from '../../features/Auth/auth-context';
import { useHVLogo } from '../../features/HVLogo/HVLogoProvider';
// @ts-ignore
import logoInfoZumHausSmall from '../../assets/Logo_iZH@1x.png';
import usePersmission from '../../features/Auth/usePersmission';

type SidebarMobileProps = {
  onClose: () => void;
  isOpen: boolean;
};

const SidebarMobile = (props: SidebarMobileProps) => {
  const { onClose, isOpen, ...restProps } = props;
  const { isAdminOrHausverwalter, isFirmendatenSelected, isAdmin } = usePersmission();
  const [, theme] = useStyletron();
  const history = useHistory();
  const location = useLocation();
  const { user, logout } = useAuth();
  const { hvLogoInfo, hvLogoURL, isLoading: isHVLogoLoading } = useHVLogo();

  if (isHVLogoLoading) {
    return <StyledSpinnerNext />;
  }

  const logoAvatar = (
    <img
      style={{ maxHeight: '32px' }}
      src={hvLogoInfo?.fotoFileId ? hvLogoURL : logoInfoZumHausSmall}
      alt={hvLogoInfo?.kurzBezeichnung ? hvLogoInfo.kurzBezeichnung : 'Alles rund um Ihr Haus'}
    />
  );

  const profileName = user?.vorname || user?.nachname || 'Profil';

  const navItemsMieter = [
    {
      title: profileName,
      itemId: ROUTES.ACCOUNT,
    },
    {
      title: 'Service',
      itemId: ROUTES.SERVICE,
    },
    {
      title: 'Objekte',
      itemId: ROUTES.OBJEKT_LIST,
    },
    {
      title: 'Dokumente',
      itemId: ROUTES.DOKUMENTE,
    },
  ];

  const navItemsHV = [
    {
      title: profileName,
      itemId: ROUTES.ACCOUNT,
    },
    {
      title: 'Benutzer',
      itemId: ROUTES.BENUTZER,
    },
    {
      title: 'Objekte',
      itemId: ROUTES.OBJEKT_LIST,
    },
    {
      title: 'Dokumente',
      itemId: ROUTES.DOKUMENTE,
    },
  ];

  const navItemsAndroAdmin = [
    {
      title: profileName,
      itemId: ROUTES.ACCOUNT,
    },
  ];

  let navigationItems;
  if (isAdminOrHausverwalter && isFirmendatenSelected) {
    // HV or AndroAdmin in HV mode
    navigationItems = navItemsHV;
  } else if (isAdmin) {
    navigationItems = navItemsAndroAdmin;
  } else {
    navigationItems = navItemsMieter;
  }

  return (
    <Drawer
      autoFocus
      size="250px"
      anchor="right"
      onClose={onClose}
      isOpen={isOpen}
      overrides={{
        DrawerBody: {
          style: ({ $theme }) => {
            return {
              marginRight: 0,
              marginLeft: 0,
              marginTop: 0,
              marginBottom: 0,
            };
          },
        },
      }}
      {...restProps}
    >
      <Block marginTop="scale600" marginLeft="scale600" marginBottom="scale800">
        {logoAvatar}
      </Block>

      <Block display="flex" height="calc(100% - 77px)" flexDirection="column">
        <Navigation
          items={navigationItems}
          activeItemId={location.pathname}
          onChange={({ event, item }) => {
            // prevent page reload
            event.preventDefault();
            history.push(item.itemId);
            onClose();
          }}
          overrides={{
            NavItem: {
              style: ({ $active, $theme }) => {
                if (!$active)
                  return {
                    ':hover': {
                      color: $theme.colors.accent,
                    },
                  };
                return {
                  backgroundColor: $theme.colors.accent200,
                  borderLeftColor: $theme.colors.accent600,
                  color: $theme.colors.mono900,
                  ':hover': {
                    color: $theme.colors.accent,
                  },
                };
              },
            },
          }}
        />

        <Block display="flex" width="100%" flexDirection="row" flex={1} alignItems="flex-end">
          <Button
            startEnhancer={
              <span role="img" aria-label="Ausloggen">
                👋
              </span>
            }
            type="button"
            aria-label="Ausloggen"
            size="large"
            kind="tertiary"
            onClick={() => logout('userLogout')}
            overrides={{
              BaseButton: { style: { borderTopStyle: 'solid', borderTopWidth: '1px', borderTopColor: theme.colors.primaryB, width: '100%' } },
            }}
          >
            Ausloggen
          </Button>
        </Block>
      </Block>
    </Drawer>
  );
};

export default SidebarMobile;
