import React from 'react';
import { Block } from 'baseui/block';
import LoadingIconIZH from './LoadingIconIZH';

const LayoutLoading = () => {
  return (
    <Block display="flex" width="100%" height="50vh" justifyContent="center" alignItems="center">
      <LoadingIconIZH />
    </Block>
  );
};

export default LayoutLoading;
