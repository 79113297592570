import { HeaderNavigation, HeaderNavigationProps, StyledNavigationItem, StyledNavigationList } from 'baseui/header-navigation';
import { useRouteMatch } from 'react-router-dom';
import { Button } from 'baseui/button';
import { FiArchive, FiHome, FiInfo, FiMoreHorizontal, FiUsers } from 'react-icons/all';
import React from 'react';
import { LabelXSmall } from 'baseui/typography';
import { Layer } from 'baseui/layer';
import { motion } from 'framer-motion';
import * as ROUTES from '../../constants/routes';
import usePersmission from '../../features/Auth/usePersmission';
import FirmendatenSelect from '../Header/FirmendatenSelect';
import SidebarMobile from './SidebarMobile';
import { BENUTZER, DOKUMENTE, OBJEKT_DETAILS, OBJEKT_LIST, SERVICE, SERVICE_CONTACT } from '../../constants/routes';
import MobileTabButton from './MobileTabButton';

// FIXME: AndroAdmin Header
type MobileTabBarProps = {
  isLoggedIn: boolean;
  onOpen: () => void;
  isOpen: boolean;
} & HeaderNavigationProps;

const MobileTabBar: React.FC<MobileTabBarProps> = ({ isLoggedIn, onOpen, isOpen, ...restProps }) => {
  const { isAdmin, isAdminOrHausverwalter, isFirmendatenSelected } = usePersmission();
  const matchObjektList = useRouteMatch(OBJEKT_LIST);
  const matchObjektDetails = useRouteMatch(OBJEKT_DETAILS);
  const matchService = useRouteMatch(SERVICE);
  const matchServiceContact = useRouteMatch(SERVICE_CONTACT);
  const matchDokumente = useRouteMatch(DOKUMENTE);
  const matchBenutzer = useRouteMatch(BENUTZER);

  let component;
  if (!isLoggedIn) {
    component = null;
  } else {
    component = (
      <React.Fragment {...restProps}>
        {isAdmin ? (
          <HeaderNavigation
            overrides={{
              Root: {
                style: ({ $theme }) => {
                  return { borderBottomWidth: 0, position: 'fixed', width: '100%', top: 0 };
                },
              },
            }}
          >
            <StyledNavigationList $align="center">
              <StyledNavigationItem $style={{ width: '320px', paddingLeft: 0 }}>
                <FirmendatenSelect size="compact" />
              </StyledNavigationItem>
            </StyledNavigationList>
          </HeaderNavigation>
        ) : null}
        <HeaderNavigation
          overrides={{
            Root: {
              style: ({ $theme }) => {
                return {
                  borderTopColor: $theme.colors.mono200,
                  borderTopStyle: 'solid',
                  borderTopWidth: '1px',
                  borderBottomStyle: 'none',
                  borderBottomWidth: 0,
                  borderLeftStyle: 'none',
                  borderLeftWidth: 0,
                  borderRightStyle: 'none',
                  borderRightWidth: 0,
                  boxShadow: $theme.lighting.shadow400,
                  backgroundColor: 'white',
                  position: 'fixed',
                  bottom: 0,
                  width: '100%',
                };
              },
            },
          }}
        >
          <StyledNavigationList $align="center">
            {isFirmendatenSelected && isAdminOrHausverwalter ? (
              <StyledNavigationItem $style={{ paddingLeft: 0 }}>
                <MobileTabButton route={ROUTES.BENUTZER} isSelected={matchBenutzer?.isExact} icon={<FiUsers />} label="Benutzer" />
              </StyledNavigationItem>
            ) : null}
            {!isAdminOrHausverwalter ? (
              <StyledNavigationItem>
                <MobileTabButton
                  route={ROUTES.SERVICE}
                  isSelected={matchService?.isExact || matchServiceContact?.isExact}
                  icon={<FiInfo />}
                  label="Service"
                />
              </StyledNavigationItem>
            ) : null}
            {isFirmendatenSelected ? (
              <StyledNavigationItem>
                <MobileTabButton
                  route={ROUTES.OBJEKT_LIST}
                  isSelected={matchObjektList?.isExact || matchObjektDetails?.isExact}
                  icon={<FiHome />}
                  label="Objekte"
                />
              </StyledNavigationItem>
            ) : null}
            {isFirmendatenSelected ? (
              <StyledNavigationItem>
                <MobileTabButton route={ROUTES.DOKUMENTE} isSelected={matchDokumente?.isExact} icon={<FiArchive />} label="Dokumente" />
              </StyledNavigationItem>
            ) : null}
            <StyledNavigationItem>
              <LabelXSmall display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <motion.div whileTap={{ scale: 1.05 }}>
                  <Button type="button" aria-label="Mehr" onClick={onOpen} kind="tertiary" shape="round" size="compact">
                    <FiMoreHorizontal />
                  </Button>
                </motion.div>
                Mehr
              </LabelXSmall>
            </StyledNavigationItem>
          </StyledNavigationList>
        </HeaderNavigation>

        <SidebarMobile onClose={onOpen} isOpen={isOpen} />
      </React.Fragment>
    );
  }

  return <Layer index={1}>{component}</Layer>;
};

export default MobileTabBar;
