import React, { useEffect } from 'react';
import createCtx from '../../helpers/createCtx';
import usePersmission from '../Auth/usePersmission';
import { useAuth } from '../Auth/auth-context';
import { HvLogoData, useHvLogo } from './gql/HVLogoQueries.generated';
import { useAsyncQuery } from '../../helpers/reactAsyncHelper';
import { getRequestInit } from '../../helpers/APIUtils';

type HVLogoContextType = {
  hvLogoInfo?: HvLogoData;
  hvLogoURL?: string;
  isLoading: boolean;
  refetch: () => void;
};

const [useHVLogo, HVLogoContextProvider] = createCtx<HVLogoContextType>();

const HVLogoProvider: React.FC = (props) => {
  const { isFirmendatenSelected } = usePersmission();
  const { activeForFirmendatenId, isLoginDone } = useAuth();

  const { data: maybeHvLogo, loading: isLoadingHVLogoInfo, refetch } = useHvLogo({
    fetchPolicy: 'cache-first',
    skip: !isLoginDone || !isFirmendatenSelected,
  });
  const hvLogoInfo = maybeHvLogo?.getFirmendaten.data ?? undefined;
  const fotoFileId = hvLogoInfo?.fotoFileId ?? undefined;

  const { data: hvLogoURL, isLoading: isLoadingHVLogo, run } = useAsyncQuery({
    deferFn: lazyLoadLogo,
    firmendatenId: activeForFirmendatenId,
  });

  useEffect(() => {
    if (fotoFileId) {
      run(fotoFileId);
    }
  }, [run, fotoFileId]);

  return <HVLogoContextProvider value={{ hvLogoInfo, hvLogoURL, isLoading: isLoadingHVLogoInfo || isLoadingHVLogo, refetch }} {...props} />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const lazyLoadLogo = async (args: any, props: any) => {
  const { firmendatenId } = props;
  const [fileId] = args;
  const res = await fetch(`/api/firmendaten/${firmendatenId}/files/${fileId}/download`, getRequestInit(firmendatenId));
  const blob = await res.blob();
  return URL.createObjectURL(blob);
};

export { HVLogoProvider, useHVLogo };
