import * as Types from '../../../types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type HvLogoQueryVariables = {};

export type HvLogoQuery = {
  getFirmendaten: {
    data: { firmendatenId: string; kurzBezeichnung: string; fotoFileId?: Types.Maybe<string>; status: { value: string } };
    errorList: Array<{ message?: Types.Maybe<string>; type?: Types.Maybe<string> }>;
    warningList: Array<{ message?: Types.Maybe<string>; type?: Types.Maybe<string> }>;
  };
};

export type HvLogoVariables = HvLogoQueryVariables;
export type HvLogoGetFirmendaten = HvLogoQuery['getFirmendaten'];
export type HvLogoData = HvLogoQuery['getFirmendaten']['data'];
export type HvLogoStatus = HvLogoQuery['getFirmendaten']['data']['status'];
export type HvLogoErrorList = NonNullable<HvLogoQuery['getFirmendaten']['errorList'][0]>;
export type HvLogoWarningList = NonNullable<HvLogoQuery['getFirmendaten']['warningList'][0]>;
export const useHvLogo = useHvLogoQuery;

export const HvLogoDocument = gql`
  query HVLogo {
    getFirmendaten {
      data {
        firmendatenId
        kurzBezeichnung
        fotoFileId
        status {
          value
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useHvLogoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HvLogoQuery, HvLogoQueryVariables>) {
  return ApolloReactHooks.useQuery<HvLogoQuery, HvLogoQueryVariables>(HvLogoDocument, baseOptions);
}
export function useHvLogoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HvLogoQuery, HvLogoQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<HvLogoQuery, HvLogoQueryVariables>(HvLogoDocument, baseOptions);
}
export type HvLogoQueryHookResult = ReturnType<typeof useHvLogoQuery>;
export type HvLogoLazyQueryHookResult = ReturnType<typeof useHvLogoLazyQuery>;
export type HvLogoQueryResult = ApolloReactCommon.QueryResult<HvLogoQuery, HvLogoQueryVariables>;
