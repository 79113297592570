import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ApolloClient, { DefaultOptions } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { from } from 'apollo-link';
import httpLink from './apolloHttpLink';
import errorLoggerLink from './apolloErrorLoggerLink';

// Disable Cache
export const apolloClientDefaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
};

const authApolloClient = () => {
  return new ApolloClient({
    name: 'authApolloClient',
    link: from([errorLoggerLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: apolloClientDefaultOptions,
  });
};

const AuthApolloProvider: React.FC = ({ children }) => {
  return <ApolloProvider client={authApolloClient()}>{children}</ApolloProvider>;
};

export default AuthApolloProvider;
