import React from 'react';
import { useAuth } from '../features/Auth/auth-context';
import { HasPermissionFunction } from './permissionChecks';

type AuthorizedProps = {
  hasPermissionFn: HasPermissionFunction;
};

const Authorized: React.FC<AuthorizedProps> = (props) => {
  const { hasPermissionFn, children } = props;
  const { mitarbeiter, user, activeForFirmendatenId } = useAuth();
  const role = user?.role;
  const funktionsModulList = mitarbeiter?.funktionsModulList;
  if (!hasPermissionFn || !hasPermissionFn({ role, funktionsModulList, activeForFirmendatenId: activeForFirmendatenId || undefined })) {
    return null;
  }
  return <>{children}</>;
};

export default Authorized;
