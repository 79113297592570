import React from 'react';
import { Label1, Label2, Paragraph1, Paragraph2, Paragraph3 } from 'baseui/typography';
import { FlexGridItem, FlexGrid } from 'baseui/flex-grid';
import { Card, StyledBody } from 'baseui/card';
import { Block } from 'baseui/block';
import { cardItemProfileProps } from '../../components/Styled/itemProps.styled';

type GeneralErrorProps = {
  componentStack?: string;
  error?: Error;
};

const GeneralError: React.FC<GeneralErrorProps> = ({ componentStack, error }) => (
  <FlexGrid>
    <FlexGridItem>
      <Card {...cardItemProfileProps}>
        <StyledBody>
          <Block display="flex" flexDirection="column" alignItems={['center', 'center', 'center', 'normal']}>
            <Label1>Hoppla!</Label1>
            <Paragraph1 display="flex" flexDirection="column" alignItems={['center', 'center', 'center', 'normal']}>
              Es ist ein Fehler aufgetreten!
              {process.env.NODE_ENV === 'production' ? (
                <Paragraph2 display="flex" flexDirection="column" alignItems={['center', 'center', 'center', 'normal']}>
                  Wir sind dabei den Fehler zu beheben…
                </Paragraph2>
              ) : (
                <Paragraph2 display="flex" flexDirection="column" alignItems={['center', 'center', 'center', 'normal']}>
                  <span role="img" aria-label="Pleading Face">
                    🥺
                  </span>{' '}
                  Something went terribly wrong
                  <Label2>
                    Error: {error ? error.toString() : ''} <br />
                    Stacktrace:
                  </Label2>
                  <Paragraph3>{componentStack}</Paragraph3>
                </Paragraph2>
              )}
            </Paragraph1>
          </Block>
        </StyledBody>
      </Card>
    </FlexGridItem>
  </FlexGrid>
);

export default GeneralError;
