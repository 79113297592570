export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type Query = {
  _empty?: Maybe<Scalars['String']>;
  getUser: UserDataResponse;
  getMe: UserDataResponse;
  getFirmendaten: FirmendatenResponse;
  getFirmendatenList: FirmendatenListResponse;
  getPersonList: PersonListResponse;
  getOwnMitarbeiterList: OwnMitarbeiterListResponse;
  getMitarbeiterMe: MitarbeiterResponse;
  getOwnProfileMitarbeiter: MitarbeiterResponse;
  getMitarbeiterList: MitarbeiterListResponse;
  getObjektList: ObjektListResponse;
  getObjekt: ObjektResponse;
  getBestandseinheitList: BestandseinheitListResponse;
  getVertragList: VertragListResponse;
  getHauseigentuemerVertragList: HauseigentuemerVertragListResponse;
  getHauseigentuemerVertrag: HauseigentuemerVertragResponse;
  getPersonObjektList: KlientObjektListResponse;
  getPersonBestandseinheitList: BestandseinheitListResponse;
  getKlientObjektList: KlientObjektListResponse;
  getKlientObjekt: ObjektResponse;
  getKlientBestandseinheitList: BestandseinheitListResponse;
  getKlientVertragList: VertragListResponse;
  getKlientHauseigentuemerVertragList: HauseigentuemerVertragListResponse;
  getInformationList: InformationListResponse;
  getNewsList: NewsListResponse;
  getNews: NewsResponse;
  getDokumentTypList: DokumentTypListResponse;
  getDokumentList: DokumentListResponse;
  getFirmendatenSyncStatus: SyncStatusResponse;
};

export type QueryGetUserArgs = {
  userId: Scalars['ID'];
};

export type QueryGetPersonListArgs = {
  name?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['ID']>;
};

export type QueryGetMitarbeiterMeArgs = {
  firmendatenId: Scalars['ID'];
};

export type QueryGetOwnProfileMitarbeiterArgs = {
  includeUser?: Maybe<Scalars['Boolean']>;
};

export type QueryGetMitarbeiterListArgs = {
  name?: Maybe<Scalars['String']>;
  pageRequest?: Maybe<PageRequestInput>;
};

export type QueryGetObjektListArgs = {
  pageRequest?: Maybe<PageRequestInput>;
  bezeichnung?: Maybe<Scalars['String']>;
};

export type QueryGetObjektArgs = {
  objektId: Scalars['ID'];
};

export type QueryGetBestandseinheitListArgs = {
  objektId: Scalars['ID'];
  withDetails?: Maybe<Scalars['Boolean']>;
};

export type QueryGetVertragListArgs = {
  objektId: Scalars['ID'];
  bestandseinheitId: Scalars['ID'];
};

export type QueryGetHauseigentuemerVertragListArgs = {
  objektId: Scalars['ID'];
};

export type QueryGetHauseigentuemerVertragArgs = {
  objektId: Scalars['ID'];
  heId: Scalars['ID'];
};

export type QueryGetPersonObjektListArgs = {
  personId: Scalars['ID'];
};

export type QueryGetPersonBestandseinheitListArgs = {
  personId: Scalars['ID'];
  objektId: Scalars['ID'];
};

export type QueryGetKlientObjektArgs = {
  objektId: Scalars['ID'];
};

export type QueryGetKlientBestandseinheitListArgs = {
  objektId: Scalars['ID'];
  withDetails?: Maybe<Scalars['Boolean']>;
};

export type QueryGetKlientVertragListArgs = {
  objektId: Scalars['ID'];
  bestandseinheitId: Scalars['ID'];
};

export type QueryGetKlientHauseigentuemerVertragListArgs = {
  objektId: Scalars['ID'];
};

export type QueryGetInformationListArgs = {
  objektId: Scalars['String'];
};

export type QueryGetNewsListArgs = {
  objektId: Scalars['ID'];
};

export type QueryGetNewsArgs = {
  objektId: Scalars['ID'];
  blackboardItemId: Scalars['ID'];
};

export type QueryGetDokumentListArgs = {
  personId: Scalars['ID'];
  objektId?: Maybe<Scalars['ID']>;
  bestandseinheitId?: Maybe<Scalars['ID']>;
  typ?: Maybe<Scalars['String']>;
  pageRequest?: Maybe<PageRequestInput>;
};

export type UserDataResponse = {
  data: User;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type User = {
  email?: Maybe<Scalars['String']>;
  emailUnverified?: Maybe<Scalars['String']>;
  role: UserRole;
  status: EnumData;
  userId: Scalars['ID'];
  vorname?: Maybe<Scalars['String']>;
  nachname?: Maybe<Scalars['String']>;
  isSubscribedToInfomail?: Maybe<Scalars['Boolean']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER',
}

export type EnumData = {
  text: Scalars['String'];
  value: Scalars['String'];
};

export type ErrorData = {
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type WarningData = {
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type FirmendatenResponse = {
  data: Firmendaten;
  errorList: Array<ErrorData>;
  warningList: Array<ErrorData>;
};

export type Firmendaten = {
  firmendatenId: Scalars['ID'];
  kurzBezeichnung: Scalars['String'];
  fotoFileId?: Maybe<Scalars['String']>;
  status: EnumData;
};

export type FirmendatenListResponse = {
  data: Array<Firmendaten>;
  errorList: Array<ErrorData>;
  warningList: Array<ErrorData>;
};

export type PersonListResponse = {
  data: Array<Person>;
  errorList: Array<ErrorData>;
  warningList: Array<ErrorData>;
};

export type Person = {
  fullName?: Maybe<Scalars['String']>;
  nachname?: Maybe<Scalars['String']>;
  ort?: Maybe<Scalars['String']>;
  personId: Scalars['ID'];
  plz?: Maybe<Scalars['String']>;
  strasse?: Maybe<Scalars['String']>;
  telefon?: Maybe<Scalars['String']>;
  vorname?: Maybe<Scalars['String']>;
  zusatz?: Maybe<Scalars['String']>;
};

export type OwnMitarbeiterListResponse = {
  data: Array<MitarbeiterWithFirmaInfo>;
  errorList: Array<ErrorData>;
  warningList: Array<ErrorData>;
};

export type MitarbeiterWithFirmaInfo = {
  mitarbeiterId: Scalars['ID'];
  firmendatenData: Firmendaten;
};

export type MitarbeiterResponse = {
  data: Mitarbeiter;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Mitarbeiter = {
  firmendatenId: Scalars['ID'];
  userId: Scalars['ID'];
  funktionsModulList: Array<FunktionsModul>;
  mitarbeiterId: Scalars['ID'];
  vorname?: Maybe<Scalars['String']>;
  nachname?: Maybe<Scalars['String']>;
  ort?: Maybe<Scalars['String']>;
  plz?: Maybe<Scalars['String']>;
  strasse?: Maybe<Scalars['String']>;
  zusatz?: Maybe<Scalars['String']>;
  telefon?: Maybe<Scalars['String']>;
  personList: Array<Person>;
  rolleList: Array<Rolle>;
  status: EnumData;
  titel?: Maybe<Scalars['String']>;
  user?: Maybe<MitarbeiterUser>;
};

export enum FunktionsModul {
  FmHausverwaltung = 'FM_HAUSVERWALTUNG',
  FmKlient = 'FM_KLIENT',
}

export type Rolle = {
  rolleId: Scalars['ID'];
  name: Scalars['String'];
};

export type MitarbeiterUser = {
  email?: Maybe<Scalars['String']>;
  emailUnverified?: Maybe<Scalars['String']>;
  nachname?: Maybe<Scalars['String']>;
  role: UserRole;
  status: EnumData;
  userId: Scalars['ID'];
  vorname?: Maybe<Scalars['String']>;
  isSubscribedToInfomail?: Maybe<Scalars['Boolean']>;
};

export type PageRequestInput = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type MitarbeiterListResponse = {
  data: MitarbeiterPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type MitarbeiterPage = {
  contentList: Array<MitarbeiterPerson>;
  page?: Maybe<Page>;
};

export type MitarbeiterPerson = {
  userId: Scalars['ID'];
  email: Scalars['String'];
  mitarbeiterId: Scalars['String'];
  nachname?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['String']>;
  rolleName: Scalars['String'];
  status: EnumData;
  vorname?: Maybe<Scalars['String']>;
  isSubscribedToInfomail?: Maybe<Scalars['Boolean']>;
};

export type Page = {
  number?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  totalElements?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type ObjektListResponse = {
  data: ObjektPage;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type ObjektPage = {
  contentList: Array<Objekt>;
  page?: Maybe<Page>;
};

export type Objekt = {
  objektId: Scalars['ID'];
  kurzBezeichnung: Scalars['String'];
  bezeichnung?: Maybe<Scalars['String']>;
  strasse?: Maybe<Scalars['String']>;
  plz?: Maybe<Scalars['String']>;
  ort?: Maybe<Scalars['String']>;
  countBestandseinheiten: Scalars['Int'];
  fotoFileId?: Maybe<Scalars['String']>;
};

export type ObjektResponse = {
  data: Objekt;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type BestandseinheitListResponse = {
  data: Array<Bestandseinheit>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type Bestandseinheit = {
  bestandseinheitId: Scalars['ID'];
  geschoss?: Maybe<Scalars['String']>;
  nummer: Scalars['String'];
  nutzflaeche?: Maybe<Scalars['Float']>;
  nutzung?: Maybe<Scalars['String']>;
  nutzwert?: Maybe<Scalars['Float']>;
  stiege?: Maybe<Scalars['String']>;
  wohnung: Scalars['String'];
  vertragList?: Maybe<Array<Vertrag>>;
};

export type Vertrag = {
  bankverbindung?: Maybe<Bankverbindung>;
  person?: Maybe<Person>;
  bis?: Maybe<Scalars['String']>;
  brutto: Scalars['Float'];
  vertragId: Scalars['ID'];
  vertragsArt: VertragsArtTuple;
  von: Scalars['String'];
  vertragspartner?: Maybe<VertragsPartner>;
  zahlungsArt: ZahlungsArtTuple;
};

export type Bankverbindung = {
  bank?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  kontoinhaber?: Maybe<Scalars['String']>;
};

export type VertragsArtTuple = {
  text: Scalars['String'];
  value: VertragsArt;
};

export enum VertragsArt {
  Eigentuemer = 'EIGENTUEMER',
  Mieter = 'MIETER',
  Untermieter = 'UNTERMIETER',
}

export type VertragsPartner = {
  rechtsTraegerId: Scalars['ID'];
  vorname: Scalars['String'];
  nachname: Scalars['String'];
};

export type ZahlungsArtTuple = {
  text: Scalars['String'];
  value: ZahlungsArt;
};

export enum ZahlungsArt {
  Lastschrift = 'LASTSCHRIFT',
  Ueberweisung = 'UEBERWEISUNG',
  Dauerauftrag = 'DAUERAUFTRAG',
  Umbuchung = 'UMBUCHUNG',
}

export type VertragListResponse = {
  data: Array<Vertrag>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type HauseigentuemerVertragListResponse = {
  data: Array<HauseigentuemerVertrag>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type HauseigentuemerVertrag = {
  heVertragId: Scalars['ID'];
  anteil?: Maybe<Scalars['Int']>;
  bankverbindung?: Maybe<Bankverbindung>;
  summeAnteil: Scalars['Float'];
  von?: Maybe<Scalars['String']>;
  bis?: Maybe<Scalars['String']>;
  person: Person;
};

export type HauseigentuemerVertragResponse = {
  data: HauseigentuemerVertrag;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type KlientObjektListResponse = {
  data: Array<Objekt>;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type InformationListResponse = {
  data: Array<Information>;
  errorList: Array<ErrorData>;
  warningList: Array<ErrorData>;
};

export type Information = {
  informationBoardItemId: Scalars['ID'];
  text: Scalars['JSON'];
};

export type NewsListResponse = {
  data: Array<News>;
  errorList: Array<ErrorData>;
  warningList: Array<ErrorData>;
};

export type News = {
  bisInklusiveDatum?: Maybe<Scalars['String']>;
  blackboardItemId: Scalars['ID'];
  vonInklusiveDatum: Scalars['String'];
  pinned: Scalars['Boolean'];
  doSendInfomail: Scalars['Boolean'];
  sentInfomailTsList: Array<Scalars['String']>;
  status?: Maybe<NewsStatus>;
  titel: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  anhangFileIdList: Array<Scalars['String']>;
  fileInfoList?: Maybe<Array<FileInfo>>;
};

export type NewsFileInfoListArgs = {
  include?: Maybe<Scalars['Boolean']>;
  objektId: Scalars['ID'];
};

export enum NewsStatus {
  Aktiv = 'AKTIV',
  Geloescht = 'GELOESCHT',
}

export type FileInfo = {
  contentType: Scalars['String'];
  createTs: Scalars['String'];
  createdBy: Scalars['String'];
  createdByMitarbeiterId?: Maybe<Scalars['ID']>;
  thumbnailExists?: Maybe<Scalars['Boolean']>;
  fileId: Scalars['ID'];
  labelList: Array<Label>;
  name: Scalars['String'];
  type: EnumData;
  dimensions?: Maybe<Dimensions>;
};

export type Label = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Dimensions = {
  width: Scalars['Int'];
  height: Scalars['Int'];
};

export type NewsResponse = {
  data: News;
  errorList: Array<ErrorData>;
  warningList: Array<ErrorData>;
};

export type DokumentTypListResponse = {
  data: Array<EnumData>;
  errorList: Array<ErrorData>;
  warningList: Array<ErrorData>;
};

export type DokumentListResponse = {
  data: DokumentPage;
  errorList: Array<ErrorData>;
  warningList: Array<ErrorData>;
};

export type DokumentPage = {
  contentList: Array<Dokument>;
  page?: Maybe<Page>;
};

export type Dokument = {
  belegDatum: Scalars['String'];
  bestandseinheitId?: Maybe<Scalars['ID']>;
  dokumentId: Scalars['ID'];
  dokumentTyp: EnumData;
  fileId: Scalars['ID'];
  objektBezeichnung?: Maybe<Scalars['String']>;
  objektId?: Maybe<Scalars['ID']>;
  personId: Scalars['ID'];
  top?: Maybe<Scalars['String']>;
};

export type SyncStatusResponse = {
  data: SyncStatus;
  errorList: Array<ErrorData>;
  warningList: Array<ErrorData>;
};

export type SyncStatus = {
  sofortLaden: Scalars['Boolean'];
  datenstandVon?: Maybe<Scalars['String']>;
};

export type Mutation = {
  _empty?: Maybe<Scalars['String']>;
  changePasswordMe: UserDataResponse;
  subscribeMeToInfomail: UserDataResponse;
  deleteHVLogo: FirmendatenResponse;
  resendEinladung: MitarbeiterResponse;
  deleteObjektFoto: ObjektResponse;
  createInformation: InformationResponse;
  updateInformation: InformationResponse;
  deleteInformation: InformationResponse;
  deleteNews: NewsResponse;
  markSofortLaden: EmptyResponse;
};

export type MutationChangePasswordMeArgs = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type MutationSubscribeMeToInfomailArgs = {
  isSubscribedToInfomail: Scalars['Boolean'];
};

export type MutationResendEinladungArgs = {
  mitarbeiterId: Scalars['ID'];
};

export type MutationDeleteObjektFotoArgs = {
  objektId: Scalars['ID'];
};

export type MutationCreateInformationArgs = {
  objektId: Scalars['String'];
  request: InformationInput;
};

export type MutationUpdateInformationArgs = {
  objektId: Scalars['String'];
  informationBoardItemId: Scalars['ID'];
  request: InformationInput;
};

export type MutationDeleteInformationArgs = {
  objektId: Scalars['String'];
  informationBoardItemId: Scalars['ID'];
};

export type MutationDeleteNewsArgs = {
  objektId: Scalars['ID'];
  blackboardItemId: Scalars['ID'];
};

export type InformationInput = {
  text: Scalars['JSON'];
};

export type InformationResponse = {
  data: Information;
  errorList: Array<ErrorData>;
  warningList: Array<ErrorData>;
};

export type EmptyResponse = {
  data?: Maybe<Scalars['Boolean']>;
  errorList: Array<ErrorData>;
  warningList: Array<ErrorData>;
};

export type ResponseEntity = {
  body?: Maybe<ResponseEntity_Body>;
  statusCode?: Maybe<Scalars['String']>;
  statusCodeValue?: Maybe<Scalars['Int']>;
};

export type ResponseEntity_Body = {
  /** default field */
  empty?: Maybe<Scalars['String']>;
};

export type BestandseinheitResponse = {
  data: Bestandseinheit;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};

export type FileResponse = {
  data: FileInfo;
  errorList: Array<ErrorData>;
  warningList: Array<WarningData>;
};
