import { createTheme, lightThemePrimitives } from 'baseui';

const primitives = {
  ...lightThemePrimitives,
  primaryFontFamily: "'Inter', system-ui, sans-serif",

  // Primary Palette
  primaryA: lightThemePrimitives.primaryA,
  primaryB: '#F7FAFC', // === mono50
  primary: '#153E75',
  primary50: '#EBF8FF',
  primary100: '#CEEDFF',
  primary200: '#90CDF4',
  primary300: '#63B3ED',
  primary400: '#4299E1',
  primary500: '#3182CE',
  primary600: '#2A69AC',
  primary700: '#1E4E8C',

  // Accent Palette
  accent: '#153E75',
  accent50: '#EBF8FF',
  accent100: '#CEEDFF',
  accent200: '#90CDF4',
  accent300: '#63B3ED',
  accent400: '#4299E1',
  accent500: '#3182CE',
  accent600: '#2A69AC',
  accent700: '#1E4E8C',

  // Alert Palette
  negative: '#822727', // Red 800
  negative50: '#FFF5F5',
  negative100: '#FED7D7',
  negative200: '#FEB2B2',
  negative300: '#FC8181',
  negative400: '#F56565',
  negative500: '#E53E3E',
  negative600: '#C53030',
  negative700: '#9B2C2C',

  // Warning Palette
  warning: '#7B341E', // 800
  warning50: '#FFFAF0',
  warning100: '#FEEBC8',
  warning200: '#FBD38D',
  warning300: '#F6AD55',
  warning400: '#ED8936',
  warning500: '#DD6B20',
  warning600: '#C05621',
  warning700: '#9C4221',

  // Success Palette
  positive: '#22543D', // Green 800
  positive50: '#F0FFF4', // Green 50
  positive100: '#C6F6D5', // Green 100
  positive200: '#9AE6B4', // Green 200
  positive300: '#68D391', // Green 300
  positive400: '#48BB78', // Green 400
  positive500: '#38A169', // Green 500
  positive600: '#2F855A', // Green 600
  positive700: '#276749', // Green 700

  // Monochrome Palette
  mono100: '#EDF2F7',
  mono200: '#E2E8F0',
  mono300: '#CBD5E0',
  mono400: '#A0AEC0',
  mono500: '#718096',
  mono600: '#4A5568',
  mono700: '#2D3748',
  mono800: '#1A202C',
  mono900: '#171923',
  mono1000: '#000',
};

const overrides = {
  typography: {
    DisplayLarge: {
      fontFamily: "'Inter', system-ui, sans-serif",
    },
    DisplayMedium: {
      fontFamily: "'Inter', system-ui, sans-serif",
    },
    DisplaySmall: {
      fontFamily: "'Inter', system-ui, sans-serif",
    },
    DisplayXSmall: {
      fontFamily: "'Inter', system-ui, sans-serif",
    },
  },
  borders: {
    inputBorderRadius: '4px',
    popoverBorderRadius: '4px',
    surfaceBorderRadius: '4px',
  },
  colors: {
    // Background
    backgroundPrimary: lightThemePrimitives.primaryB,
    backgroundSecondary: lightThemePrimitives.primaryB,
    // backgroundTertiary: ,

    // Content
    contentPrimary: primitives.mono900, // TODO check color A11Y
    contentSecondary: primitives.mono600, // placeholder texts
    // contentTertiary: primitives.mono300,

    // Border
    // borderOpaque: ,
    // borderTransparent: ,
    borderSelected: primitives.accent700, // primitives.accent

    // Border
    borderStateDisabled: primitives.negative,
    borderAccent: primitives.accent,
    borderAccentLight: primitives.accent50,
    borderNegative: primitives.negative400,
    borderWarning: primitives.warning400,
    borderPositive: primitives.positive400,

    // Font Color
    colorPrimary: primitives.mono900,
    colorSecondary: primitives.mono600,

    // Background
    background: lightThemePrimitives.accent50,
    // backgroundAlt: lightThemePrimitives.primaryA,

    // Foreground
    foreground: primitives.primaryB,
    // foregroundAlt: lightThemePrimitives.accent50,
    // foregroundInv: lightThemePrimitives.accent50,

    // Borders
    border: primitives.mono200,
    borderAlt: primitives.accent50,
    borderFocus: primitives.accent,
    borderError: primitives.negative,

    // Links
    linkText: primitives.mono600,
    linkVisited: primitives.mono600,
    linkHover: primitives.mono700,
    linkActive: primitives.mono600,

    // Buttons
    buttonSecondaryFill: primitives.mono100, //
    buttonSecondaryText: primitives.accent,
    buttonSecondaryHover: primitives.mono200,
    buttonSecondaryActive: primitives.mono300,
    buttonSecondarySelectedFill: primitives.mono400,
    buttonSecondarySelectedText: primitives.accent,

    buttonSecondarySpinnerForeground: primitives.accent700,
    buttonSecondarySpinnerBackground: primitives.accent300,

    // Input
    inputBorder: primitives.mono100,
    inputFill: primitives.mono100,
    inputFillActive: primitives.mono100,
    inputFillError: primitives.negative50,
    inputFillDisabled: primitives.mono100,
    inputTextDisabled: primitives.mono900,
    inputPlaceholder: primitives.mono500,
    inputPlaceholderDisabled: primitives.mono400,
  },
};

const theme = createTheme(primitives, overrides);

export default theme;
