import { FunktionsModul, UserRole } from '../types';

type PermissionInfo = {
  role?: UserRole;
  funktionsModulList?: FunktionsModul[];
  activeForFirmendatenId?: string;
};

export interface HasPermissionFunction {
  (permissionInfo: PermissionInfo): boolean;
}

export const isAdmin: HasPermissionFunction = ({ role }) => role === UserRole.Admin;

export const isUser: HasPermissionFunction = ({ role }) => role === UserRole.User;

export const hasHausverwaltungFm: HasPermissionFunction = ({ funktionsModulList }) => {
  return hasFm({ funktionsModulList, fmToFind: FunktionsModul.FmHausverwaltung });
};

export const hasHausverwaltungOrOrKlientFm: HasPermissionFunction = ({ funktionsModulList }) => {
  return (
    hasFm({
      funktionsModulList,
      fmToFind: FunktionsModul.FmHausverwaltung,
    }) || hasFm({ funktionsModulList, fmToFind: FunktionsModul.FmKlient })
  );
};

export const isAdminOrHasHausverwaltungFm: HasPermissionFunction = ({ role, funktionsModulList }) => {
  return isAdmin({ role }) || hasFm({ funktionsModulList, fmToFind: FunktionsModul.FmHausverwaltung });
};

export const isAdminOrHasHausverwaltungOrKlientFm: HasPermissionFunction = ({ role, funktionsModulList }) => {
  return isAdmin({ role }) || hasHausverwaltungOrOrKlientFm({ funktionsModulList });
};

export const isActiveForSomeFirmendaten: HasPermissionFunction = ({ activeForFirmendatenId }) => !!activeForFirmendatenId;

interface HasFmParameters {
  funktionsModulList?: FunktionsModul[];
  fmToFind: FunktionsModul;
}

const hasFm = ({ funktionsModulList = [], fmToFind }: HasFmParameters) => funktionsModulList && funktionsModulList.includes(fmToFind);
