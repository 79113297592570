import { CardProps } from 'baseui/card';
import { ButtonProps } from 'baseui/button';
import { LightTheme } from 'baseui';
import { PaginationOverrides } from 'baseui/pagination';
import theme from '../../theme';

const buttonItemProps: ButtonProps = {
  overrides: {
    BaseButton: { style: { width: '100%' } },
  },
};

const objektItemButtonProps: ButtonProps = {
  overrides: {
    BaseButton: {
      style: ({ $theme }: { $theme: typeof LightTheme }) => {
        return {
          width: '100%',
          borderBottomLeftRadius: $theme.borders.radius200,
          borderBottomRightRadius: $theme.borders.radius200,
        };
      },
    },
  },
};

const cardItemRootStyles = {
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderTopWidth: 0,
  borderBottomWidth: 0,
  boxShadow: theme.lighting.shadow400,
  borderTopLeftRadius: theme.borders.radius200,
  borderTopRightRadius: theme.borders.radius200,
  borderBottomLeftRadius: theme.borders.radius200,
  borderBottomRightRadius: theme.borders.radius200,
};

const cardItemInformationProps: CardProps = {
  overrides: {
    Root: {
      style: ({ $theme }: { $theme: typeof LightTheme }) => {
        return {
          ...cardItemRootStyles,
        };
      },
    },
  },
};

const cardItemProfileProps: CardProps = {
  overrides: {
    Root: {
      style: ({ $theme }: { $theme: typeof LightTheme }) => {
        return {
          ...cardItemRootStyles,
          padding: $theme.sizing.scale600,
        };
      },
    },
  },
};

const cardItemKontaktProps: CardProps = {
  overrides: {
    Root: {
      style: ({ $theme }: { $theme: typeof LightTheme }) => {
        return {
          ...cardItemRootStyles,
          userSelect: 'none',
          minWidth: '220px',
          padding: $theme.sizing.scale600,
          boxShadow: $theme.lighting.shadow700,
          transition: $theme.animation.timing100,
          cursor: 'pointer',
          ':hover': {
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: $theme.colors.accent600,
            boxShadow: $theme.lighting.shadow400,
            color: $theme.colors.accent700,
          },
        };
      },
    },
  },
};

const cardItemKontaktMotion = {
  rest: { scale: 1 },
  hover: { scale: 1 },
  pressed: { scale: 1.03 },
};

const cardItemSchwarzesBrettProps: CardProps = {
  overrides: {
    Root: {
      style: {
        ...cardItemRootStyles,
      },
    },
    Body: {
      style: ({ $theme }: { $theme: typeof LightTheme }) => {
        return {
          paddingBottom: $theme.sizing.scale600,
        };
      },
    },
  },
};

const cardItemLayoutAuthProps: CardProps = {
  overrides: {
    Root: {
      style: ({ $theme }: { $theme: typeof LightTheme }) => {
        return {
          ...cardItemRootStyles,
          [$theme.mediaQuery.large]: {
            paddingTop: $theme.sizing.scale800,
            paddingBottom: $theme.sizing.scale800,
            paddingLeft: $theme.sizing.scale800,
            paddingRight: $theme.sizing.scale800,
          },
          [$theme.mediaQuery.medium]: {
            paddingTop: $theme.sizing.scale400,
            paddingBottom: $theme.sizing.scale400,
            paddingLeft: $theme.sizing.scale400,
            paddingRight: $theme.sizing.scale400,
          },
          [$theme.mediaQuery.small]: {
            paddingTop: $theme.sizing.scale200,
            paddingBottom: $theme.sizing.scale200,
            paddingLeft: $theme.sizing.scale200,
            paddingRight: $theme.sizing.scale200,
          },
        };
      },
    },
  },
};

const cardStyledProps: CardProps = {
  overrides: {
    Root: {
      style: {
        ...cardItemRootStyles,
      },
    },
    HeaderImage: {
      style: ({ $theme }: { $theme: typeof LightTheme }) => {
        return {
          overflow: 'hidden',
          objectFit: 'cover',
          width: '720px',
          height: '300px',
          borderTopLeftRadius: $theme.borders.radius200,
          borderTopRightRadius: $theme.borders.radius200,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        };
      },
    },
  },
};

const listItemProps = {
  paddingLeft: 0,
  paddingRight: 0,
};

const paginationMaxLabelProps: PaginationOverrides = {
  MaxLabel: {
    style: ({ $theme }) => {
      return {
        fontSize: '12px',
        marginRight: $theme.sizing.scale100,
        marginLeft: $theme.sizing.scale100,
      };
    },
  },
};

export {
  buttonItemProps,
  objektItemButtonProps,
  cardItemInformationProps,
  cardItemKontaktProps,
  cardItemKontaktMotion,
  cardItemProfileProps,
  cardItemSchwarzesBrettProps,
  cardItemLayoutAuthProps,
  cardStyledProps,
  listItemProps,
  paginationMaxLabelProps,
};
