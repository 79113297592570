import React, { useEffect } from 'react';
import { AsyncOptions, AsyncState, useAsync } from 'react-async';
import { toaster } from 'baseui/toast';
import { useAuth } from '../features/Auth/auth-context';
import { HTTP_STATUS } from './APIUtils';
import { showUnauthorizedError } from '../components/Notification/notificationHelper';
import { EasyErrorType } from '../components/Notification/ErrorNotification';
import { ErrorNotification } from '../components/Notification';

/**
 * Here you can find some custom hooks that are using react-async's useAsync but they are also performing some common error handling.
 *
 * if you need custom error handling with react-async then you should use it directly in your code instead of using one of theese
 */

/**
 * use this hook if user logged in and you need to perform a POST/PUT/PATCH => errors will be handled here
 * 401 => logout and showUnauthorizedError
 * 400 with errors from Java backend => show error notification with the errors from BE
 * other errors: show error notification with unexpected error
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAsyncMutation = <T extends any>(options: AsyncOptions<T>): AsyncState<T> => {
  const { user, logout } = useAuth();
  const asyncState = useAsync(options);
  const { error } = asyncState;
  const shouldShowError = !!options.onReject;

  useEffect(() => {
    if (error) {
      // @ts-ignore
      if (user && error.status === HTTP_STATUS.UNAUTHORIZED) {
        logout('unauthorized');
        showUnauthorizedError();
      } else {
        // @ts-ignore
        const errorList = error.data?.errorList as EasyErrorType[];
        if (!shouldShowError) {
          toaster.info(<ErrorNotification errors={errorList} />, {});
        }
      }
    }
  }, [logout, error, user, shouldShowError]);

  return asyncState;
};

/**
 * use this hook if user logged in and you need to perform a GET => errors will be handled here.
 * 401 => logout and showUnauthorizedError
 * not showing error for other type of error because when fetching you should be prepared when you get an error => showing empty state or error locally where
 * it happens not in a notification area
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAsyncQuery = <T extends any>(options: AsyncOptions<T>): AsyncState<T> => {
  const { logout } = useAuth();
  const asyncState = useAsync(options);
  const { error } = asyncState;

  useEffect(() => {
    if (error) {
      // @ts-ignore
      if (error.status === HTTP_STATUS.UNAUTHORIZED) {
        logout('unauthorized');
        showUnauthorizedError();
      }
    }
  }, [logout, error]);

  return asyncState;
};
