import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient, { DefaultOptions } from 'apollo-client';
import { ApolloLink, from } from 'apollo-link';
import { GraphQLError } from 'graphql';
import { isEmpty } from 'lodash-es';
import { onError } from 'apollo-link-error';
import { toaster } from 'baseui/toast';
import httpLink from './apolloHttpLink';
import { LogoutReason, useAuth } from '../features/Auth/auth-context';
import { ErrorNotification } from '../components/Notification';
import { HTTP_STATUS } from '../helpers/APIUtils';
import errorLoggerLink from './apolloErrorLoggerLink';
import { showUnauthorizedError } from '../components/Notification/notificationHelper';
import { HEADER_EASYPRO_WEB_ACTIVE_FOR_FIRMENDATEN_ID, HEADER_EASYPRO_WEB_FIRMENDATEN_ID } from '../helpers/easyProWebHeaders';

export const firmendatenHeaderMiddleware = (mitarbeiterFirmendatenId?: string | null, activeForFirmendatenId?: string | null) =>
  new ApolloLink((operation, forward) => {
    const headers: { [HEADER_EASYPRO_WEB_FIRMENDATEN_ID]?: string; [HEADER_EASYPRO_WEB_ACTIVE_FOR_FIRMENDATEN_ID]?: string } = {};

    if (mitarbeiterFirmendatenId) {
      headers[HEADER_EASYPRO_WEB_FIRMENDATEN_ID] = mitarbeiterFirmendatenId;
    }
    if (activeForFirmendatenId) {
      headers[HEADER_EASYPRO_WEB_ACTIVE_FOR_FIRMENDATEN_ID] = activeForFirmendatenId;
    }

    if (!isEmpty(headers)) {
      operation.setContext({
        headers,
      });
    }

    return forward(operation);
  });

export const errorHandlerLink = (logout: (reason: LogoutReason) => void) =>
  onError(({ graphQLErrors, networkError }) => {
    const toastKeyUnexpectedError = 'unexpected-error';
    const unexpectedErrorMsgTitle = '👾 Ein Fehler ist aufgetreten';

    if (graphQLErrors) {
      const errors = graphQLErrors.flatMap(getValidationErrorsIfAny);
      if (errors.length !== 0) {
        toaster.info(<ErrorNotification errors={errors} />, {});
      } else {
        toaster.info(<ErrorNotification title={unexpectedErrorMsgTitle} />, { key: toastKeyUnexpectedError });
      }
    }

    if (networkError) {
      if (isUnauthorizedError(networkError)) {
        logout('unauthorized');
        showUnauthorizedError();
      } else {
        toaster.info(<ErrorNotification title={unexpectedErrorMsgTitle} />, { key: toastKeyUnexpectedError });
      }
    }
  });

const getValidationErrorsIfAny = (graphqlError: GraphQLError) => {
  const extensions = graphqlError?.extensions ?? [];
  if (isValidationError(extensions)) {
    return extensions.exception.errorList;
  }
  return isValidationError(extensions) ? extensions.exceptions.errorList : [];
};

const isValidationError = (extensions: { [p: string]: unknown }) => {
  return Object.prototype.hasOwnProperty.call(extensions, 'exception') && Object.prototype.hasOwnProperty.call(extensions.exception, 'errorList');
};

const isUnauthorizedError = (networkError: unknown) => {
  // @ts-ignore
  return Object.prototype.hasOwnProperty.call(networkError, 'statusCode') && networkError.statusCode === HTTP_STATUS.UNAUTHORIZED;
};

const apolloClientLinks = (
  logout: (reason: LogoutReason) => void,
  mitarbeiterFirmendatenId?: string | null,
  activeForFirmendatenId?: string | null
) => from([firmendatenHeaderMiddleware(mitarbeiterFirmendatenId, activeForFirmendatenId), errorHandlerLink(logout), errorLoggerLink, httpLink]);

export const apolloClientDefaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
};

const mainApolloClient = (
  logout: (reason: LogoutReason) => void,
  mitarbeiterFirmendatenId?: string | null,
  activeForFirmendatenId?: string | null
) => {
  return new ApolloClient({
    name: 'mainApolloClient',
    link: apolloClientLinks(logout, mitarbeiterFirmendatenId, activeForFirmendatenId),
    cache: new InMemoryCache(),
    defaultOptions: apolloClientDefaultOptions,
  });
};

const MainApolloProvider: React.FC = ({ children }) => {
  const { mitarbeiter, activeForFirmendatenId, logout } = useAuth();
  return <ApolloProvider client={mainApolloClient(logout, mitarbeiter?.firmendatenId, activeForFirmendatenId)}>{children}</ApolloProvider>;
};

export default MainApolloProvider;
