import { createBreakpoint } from 'react-use';

export enum Breakpoints {
  XL = 1280,
  LG = 992,
  MD = 768,
  S = 480,
}

export const isLastItem = (array: unknown[], currentIndex: number) => array.length === currentIndex + 1;

const useBreakpoint = createBreakpoint({ XL: Breakpoints.XL, LG: Breakpoints.LG, MD: Breakpoints.MD, S: Breakpoints.S });

export default useBreakpoint;
