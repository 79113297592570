/* eslint-disable no-template-curly-in-string */
const localeOverrideDe = {
  accordion: {
    collapse: 'Schließen',
    expand: 'Öffnen',
  },
  breadcrumbs: {
    ariaLabel: 'Breadcrumbs Navigation',
  },
  datepicker: {
    timePickerAriaLabel: 'Zeit auswählen', // with v10 deprecated
    ariaLabel: 'Datum auswählen.',
    ariaLabelRange: 'Zeitraum auswählen.',
    ariaLabelCalendar: 'Kalender.',
    ariaRoleDescriptionCalendarMonth: 'Kalendermonat',
    previousMonth: 'Vorheriger Monat.',
    nextMonth: 'Nächsten Monat.',
    pastWeek: 'Letzte Woche',
    pastMonth: 'Vorheriger Monat',
    pastThreeMonths: 'Letzte 3 Monate',
    pastSixMonths: 'Letzten 6 Monate',
    pastYear: 'Past Year',
    pastTwoYears: 'Past 2 Years',
    screenReaderMessageInput:
      'Press the down arrow key to interact with the calendar and select a date. Press the escape button to close the calendar.',
    selectedDate: 'Selected date is ${date}.',
    selectedDateRange: 'Selected date range is from ${startDate} to ${endDate}.',
    selectSecondDatePrompt: 'Select the second date.',
    quickSelectLabel: 'Choose a date range',
    quickSelectAriaLabel: 'Choose a date range',
    quickSelectPlaceholder: 'None',
    timeSelectEndLabel: 'End time',
    timeSelectStartLabel: 'Start time',
    timePickerAriaLabel12Hour: 'Zeit auswählen, 12-Stundenformat.',
    timePickerAriaLabel24Hour: 'Zeit auswählen, 24-Stundenformat.',
    timezonePickerAriaLabel: 'Select a timezone.',
    selectedStartDateLabel: 'Selected start date.',
    selectedEndDateLabel: 'Selected end date.',
    dateNotAvailableLabel: 'Not available.',
    dateAvailableLabel: "It's available.",
    selectedLabel: 'Selected.',
    chooseLabel: 'Choose',
  },
  datatable: {
    emptyState:
      'Keine Zeilen entsprechen den definierten Filterkriterien. Bitte entfernen Sie einen oder mehrere Filter, um weitere Daten anzuzeigen.',
    loadingState: 'Zeilen laden.',
  },
  fileuploader: {
    dropFilesToUpload: 'Legen Sie hier Dateien ab, um sie hochzuladen',
    or: 'oder',
    browseFiles: 'Dateien hinzufügen',
    retry: 'Upload wiederholen',
    cancel: 'Abbrechen',
  },
  menu: {
    noResultsMsg: 'Keine Resultate',
    parentMenuItemAriaLabel:
      'You are currently at an item than opens a nested listbox. Press right arrow to enter that element and left arrow to return.',
  },
  modal: {
    close: 'Schließen',
  },
  drawer: {
    close: 'Schließen',
  },
  pagination: {
    prev: 'Vor',
    next: 'Nächste',
    preposition: 'von',
  },
  select: {
    noResultsMsg: 'Keine Resultate gefunden',
    placeholder: 'Auswählen...',
    create: 'Erzeugen',
  },
  toast: {
    close: 'Schließen',
  },
};
/* eslint-enable no-template-curly-in-string */

export default localeOverrideDe;
