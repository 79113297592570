import React from 'react';
import { toaster } from 'baseui/toast';
import { ErrorNotification } from './index';

export const showUnauthorizedError = () => {
  const toastKeyUnauthorizedError = 'unauthorized-error';
  toaster.info(
    <ErrorNotification
      title="Sitzung abgelaufen"
      errors={[{ type: 'Sitzung abgelaufen', message: `Ihre Sitzung ist abgelaufen, bitte loggen Sie sich erneut ein.` }]}
    />,
    { key: toastKeyUnauthorizedError } // it ensures that only 1 error is shown if this type
  );
};
