import { HEADER_EASYPRO_WEB_FIRMENDATEN_ID } from './easyProWebHeaders';

export enum HTTP_STATUS {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  SERVICE_UNAVAILABLE = 503,
}

export function processResponse(response: Response) {
  return new Promise((resolve, reject) => {
    const func = mapStatusCodeToResolveOrRejectFunction(response.status, resolve, reject);
    response
      .json()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => func({ status: response.status, statusText: response.statusText, data }))
      .catch(() => func({ status: response.status, statusText: response.statusText }));
  });
}

const mapStatusCodeToResolveOrRejectFunction = (
  statusCode: number,
  resolve: { (value?: unknown): void; (value?: unknown): void },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject: { (reason?: any): void; (reason?: any): void }
) => (statusCode < 400 ? resolve : reject);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const processAndReturnError = (response: { status: number; data: { errorList: any }; statusText: any }) =>
  new Promise((_, reject) => {
    if (response.status === HTTP_STATUS.BAD_REQUEST) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return reject({ error: true, validationError: true, errorList: response.data.errorList });
    }
    if (response.status === HTTP_STATUS.UNAUTHORIZED) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return reject({ error: true, unauthorizedError: true, validationError: false, errorList: response.statusText });
    }
    if (response.status === HTTP_STATUS.NOT_FOUND) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return reject({ error: true, notFound: true, validationError: false, errorList: response.statusText });
    }
    if (response.status === HTTP_STATUS.SERVICE_UNAVAILABLE) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return reject({ error: true, serviceUnavailable: true, validationError: false, errorList: response.statusText });
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return reject({ error: true, validationError: false, message: response.statusText, details: response });
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const returnData = (response: any) => response.data.data;

export const getRequestInit = (firmendatenId: string): RequestInit => ({
  method: 'GET',
  credentials: 'same-origin',
  headers: {
    [HEADER_EASYPRO_WEB_FIRMENDATEN_ID]: firmendatenId,
    Accept: 'application/json',
  },
});

export const postRequestInit = (firmendatenId: string, body: BodyInit | null): RequestInit => ({
  method: 'POST',
  body,
  credentials: 'same-origin',
  headers: {
    [HEADER_EASYPRO_WEB_FIRMENDATEN_ID]: firmendatenId,
    Accept: 'application/json',
  },
});

export const putRequestInit = (firmendatenId: string, body: BodyInit | null): RequestInit => ({
  method: 'PUT',
  body,
  credentials: 'same-origin',
  headers: {
    [HEADER_EASYPRO_WEB_FIRMENDATEN_ID]: firmendatenId,
    Accept: 'application/json',
  },
});
