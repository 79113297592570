import React from 'react';
import { Option, Select, SelectProps } from 'baseui/select';
import { useFirmendatenSelectList } from './gql/firmendatenQueries.generated';
import { useAuth } from '../../features/Auth/auth-context';

type FirmendatenSelectProps = {} & SelectProps;

const FirmendatenSelect: React.FC<FirmendatenSelectProps> = (props) => {
  const { activeForFirmendatenId, setActiveForFirmendatenId, clearActiveForFirmendatenId } = useAuth();
  const selectedFirmendaten = activeForFirmendatenId ? mapFirmendatenListToSelectList([{ firmendatenId: activeForFirmendatenId }]) : undefined;

  const { data, loading } = useFirmendatenSelectList();
  const firmendatenList = data?.getFirmendatenList.data ?? [];
  const activeFirmendaten = firmendatenList.filter((f) => f.status.value !== 'GELOESCHT');
  const selectList = mapFirmendatenListToSelectList(activeFirmendaten);

  return (
    <Select
      type="search"
      placeholder="Kunden auswählen"
      isLoading={loading}
      options={selectList}
      valueKey="id"
      labelKey="label"
      onChange={(params) => {
        if (params.value.length === 0) {
          // firma cleared
          clearActiveForFirmendatenId();
        } else if (params.option?.id) {
          // firma selected
          setActiveForFirmendatenId(params.option?.id as string);
        }
      }}
      value={selectedFirmendaten}
      {...props}
    />
  );
};

export const mapFirmendatenListToSelectList = (firmendatenList: Array<{ firmendatenId: string; kurzBezeichnung?: string }>): Option[] => {
  return firmendatenList.map((firmendaten) => ({ id: firmendaten.firmendatenId, label: firmendaten.kurzBezeichnung } as Option));
};

export default FirmendatenSelect;
