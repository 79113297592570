import React, { PropsWithChildren, useReducer } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';
import { toaster } from 'baseui/toast';
import { FunktionsModul, UserRole } from '../../types';
import createCtx from '../../helpers/createCtx';
import LayoutStartUp from '../../components/Layout/LayoutStartUp';
import { callLogout } from './loginApi';
import { LoginMitarbeiterListData, useLoginMitarbeiterList, useMe, useMitarbeiterMe } from './gql/AuthQueries.generated';

export type User = {
  email?: string | null;
  vorname?: string | null;
  nachname?: string | null;
  role: UserRole;
  userId: string;
};

type Mitarbeiter = {
  firmendatenId: string;
  personId?: string | null;
  funktionsModulList: FunktionsModul[];
  mitarbeiterId: string;
  rolleList: { name: string; rolleId: string }[];
};

type AuthContextType = {
  user?: User;
  reloadUser: () => void;
  isAuthenticating: boolean;
  logout: (reason: LogoutReason) => void;
  mitarbeiterCount?: number;
  mitarbeiter?: Mitarbeiter;
  activeForFirmendatenId: string | null;
  setActiveForFirmendatenId: (firmendatenId: string) => void;
  clearActiveForFirmendatenId: () => void;
  isLoginDone: boolean;
  logoutReason?: LogoutReason;
};

const [useAuth, AuthContextProvider] = createCtx<AuthContextType>();

type AuthState = {
  initFinished?: boolean;
  user?: User;
  mitarbeiterList?: LoginMitarbeiterListData[];
  mitarbeiter?: Mitarbeiter;
  logoutReason?: LogoutReason;
};

export type LogoutReason = 'userLogout' | 'unauthorized';

type AuthAction =
  | {
      type: 'LOAD_USER_SUCCESS';
      payload: {
        user: User;
        activeForFirmendatenId: string | null;
      };
    }
  | {
      type: 'CLEAR_STATE';
      payload: { logoutReason: LogoutReason };
    }
  | {
      type: 'LOAD_MITARBEITER_LIST_SUCCESS';
      payload: LoginMitarbeiterListData[];
    }
  | {
      type: 'LOAD_MITARBEITER_SUCCESS';
      payload: Mitarbeiter;
    };

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'LOAD_USER_SUCCESS':
      // eslint-disable-next-line no-case-declarations
      const isAdminWithoutActiveFirmendatenId = action.payload.user.role === UserRole.Admin || !action.payload.activeForFirmendatenId;
      return { ...state, user: action.payload.user, initFinished: isAdminWithoutActiveFirmendatenId };
    case 'CLEAR_STATE':
      return { initFinished: true, logoutReason: action.payload?.logoutReason };
    case 'LOAD_MITARBEITER_LIST_SUCCESS':
      return { ...state, mitarbeiterList: action.payload };
    case 'LOAD_MITARBEITER_SUCCESS':
      return { ...state, mitarbeiter: action.payload, initFinished: true };
    default:
      throw new Error(`unsupported auth action: ${action}`);
  }
};

const useInitUserAndMitarbeiter = () => {
  const [activeForFirmendatenId, setActiveForFirmendatenId, removeActiveForFirmendatenId] = useLocalStorage('activeForFirmendatenId');
  const [{ initFinished, user, mitarbeiterList, mitarbeiter, logoutReason }, dispatch] = useReducer(authReducer, {});
  const clearActiveForFirmendatenId = () => removeActiveForFirmendatenId();

  const clearState = (logoutReason: LogoutReason) => {
    dispatch({ type: 'CLEAR_STATE', payload: { logoutReason } });
    clearActiveForFirmendatenId();
  };

  const logout = (reason: LogoutReason) => callLogout().finally(() => clearState(reason));

  const { loading: isUserLoading, error: userError, refetch } = useMe({
    onCompleted: (data) => dispatch({ type: 'LOAD_USER_SUCCESS', payload: { user: data.getMe.data, activeForFirmendatenId } }),
    // calling only clearState without calling logout because if this query does not work user is unauthorized anyway, no need for extra logout
    onError: () => clearState('unauthorized'),
    // https://github.com/apollographql/react-apollo/issues/3571
    fetchPolicy: 'cache-and-network',
  });

  const { error: mitarbeiterListError, loading: isMitarbeiterListLoading } = useLoginMitarbeiterList({
    onCompleted: (data) => {
      dispatch({ type: 'LOAD_MITARBEITER_LIST_SUCCESS', payload: data.getOwnMitarbeiterList.data });
      const mitarbeiterCount = data.getOwnMitarbeiterList.data.length;
      if (mitarbeiterCount === 1) {
        setActiveForFirmendatenId(data.getOwnMitarbeiterList.data[0].firmendatenData.firmendatenId);
      } else if (mitarbeiterCount > 1) {
        // do nothing
      } else {
        // mitarbeiterCount === 0
        toaster.negative('Login war fehlerhaft', {});
        logout('unauthorized');
      }
    },
    onError: () => logout('unauthorized'),
    // skip if no user available yet or ...
    // user is ANDRO_ADMIN => they don't have mitarbeiter or ...
    // a firma has been already selected => then we only have to load the mitarbeiterData to the selected firma
    skip: !user || isUserLoading || user.role === UserRole.Admin || !!activeForFirmendatenId,
  });

  const mitarbeiterCount = mitarbeiterList ? mitarbeiterList.length : undefined;

  const { error: mitarbeiterError, loading: isMitarbeiterLoading } = useMitarbeiterMe({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { firmendatenId: activeForFirmendatenId! },
    onCompleted: (data) => dispatch({ type: 'LOAD_MITARBEITER_SUCCESS', payload: data.getMitarbeiterMe.data }),
    onError: () => logout('unauthorized'),
    // skip if no user available yet or ...
    // user is ANDRO_ADMIN => they don't have mitarbeiter or ...
    // a firma has not yet been selected => we have to know to which firma the mitarbeiter belongs in advance
    skip: !user || isUserLoading || user.role === UserRole.Admin || !activeForFirmendatenId,
  });

  return {
    user,
    mitarbeiterCount,
    mitarbeiter,
    activeForFirmendatenId,
    clearActiveForFirmendatenId,
    setActiveForFirmendatenId,
    initFinished,
    isRejected: userError || mitarbeiterListError || mitarbeiterError,
    reloadUser: refetch,
    isAuthenticating: isUserLoading || isMitarbeiterListLoading || isMitarbeiterLoading,
    logout,
    logoutReason,
  };
};

const AuthProvider = (props: PropsWithChildren<{}>) => {
  const {
    initFinished,
    isRejected,
    user,
    mitarbeiterCount,
    mitarbeiter,
    activeForFirmendatenId,
    setActiveForFirmendatenId,
    clearActiveForFirmendatenId,
    reloadUser,
    isAuthenticating,
    logout,
    logoutReason,
  } = useInitUserAndMitarbeiter();

  const isLoginDone = !!user && (user.role === UserRole.Admin || (user.role === UserRole.User && !!activeForFirmendatenId && !!mitarbeiter));

  if (!initFinished) {
    if (isRejected) {
      // ?
    } else {
      return <LayoutStartUp />;
    }
  }

  return (
    <AuthContextProvider
      value={{
        user,
        reloadUser,
        logout,
        mitarbeiterCount,
        mitarbeiter,
        activeForFirmendatenId,
        clearActiveForFirmendatenId,
        setActiveForFirmendatenId,
        isAuthenticating,
        isLoginDone,
        logoutReason,
      }}
      {...props}
    />
  );
};

export { AuthProvider, useAuth };
