import React from 'react';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { useStyletron } from 'baseui';

export interface EasyErrorType {
  type: string;
  message: string;
}

type ErrorNotificationProps = {
  title?: string;
  errors?: EasyErrorType[];
};

const ErrorNotification: React.FC<ErrorNotificationProps> = ({ title, errors }) => {
  const [, theme] = useStyletron();
  const errorTitle = title || '👾 Ein Fehler ist aufgetreten.';

  if (!errors) {
    return <LabelMedium color={theme.colors.white}>{errorTitle}</LabelMedium>;
  }
  return (
    <>
      <LabelMedium color={theme.colors.white}>{errorTitle}</LabelMedium>
      <ParagraphSmall color={theme.colors.white}>
        {errors.map((error) => (
          <React.Fragment key={error.message}>
            {error.message}
            <br />
          </React.Fragment>
        ))}
      </ParagraphSmall>
    </>
  );
};

export default ErrorNotification;
