import React from 'react';
import { Block } from 'baseui/block';
import { LabelXSmall } from 'baseui/typography';
import LoadingIconIZH from './LoadingIconIZH';

const LayoutStartUp = () => {
  return (
    <Block display="flex" width="100vw" height="100vw" flexDirection="column" justifyContent="center" alignItems="center">
      <LoadingIconIZH />
      <LabelXSmall>Laden</LabelXSmall>
    </Block>
  );
};

export default LayoutStartUp;
