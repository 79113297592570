import * as Types from '../../../types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type MeQueryVariables = {};

export type MeQuery = {
  getMe: {
    data: { userId: string; vorname?: Types.Maybe<string>; nachname?: Types.Maybe<string>; email?: Types.Maybe<string>; role: Types.UserRole };
    errorList: Array<{ message?: Types.Maybe<string>; type?: Types.Maybe<string> }>;
    warningList: Array<{ message?: Types.Maybe<string>; type?: Types.Maybe<string> }>;
  };
};

export type LoginMitarbeiterListQueryVariables = {};

export type LoginMitarbeiterListQuery = {
  getOwnMitarbeiterList: {
    data: Array<{ firmendatenData: { firmendatenId: string } }>;
    errorList: Array<{ message?: Types.Maybe<string>; type?: Types.Maybe<string> }>;
    warningList: Array<{ message?: Types.Maybe<string>; type?: Types.Maybe<string> }>;
  };
};

export type MitarbeiterMeQueryVariables = {
  firmendatenId: Types.Scalars['ID'];
};

export type MitarbeiterMeQuery = {
  getMitarbeiterMe: {
    data: {
      vorname?: Types.Maybe<string>;
      nachname?: Types.Maybe<string>;
      firmendatenId: string;
      funktionsModulList: Array<Types.FunktionsModul>;
      mitarbeiterId: string;
      rolleList: Array<{ name: string; rolleId: string }>;
    };
  };
};

export type MeVariables = MeQueryVariables;
export type MeGetMe = MeQuery['getMe'];
export type MeData = MeQuery['getMe']['data'];
export type MeErrorList = NonNullable<MeQuery['getMe']['errorList'][0]>;
export type MeWarningList = NonNullable<MeQuery['getMe']['warningList'][0]>;
export const useMe = useMeQuery;
export type LoginMitarbeiterListVariables = LoginMitarbeiterListQueryVariables;
export type LoginMitarbeiterListGetOwnMitarbeiterList = LoginMitarbeiterListQuery['getOwnMitarbeiterList'];
export type LoginMitarbeiterListData = NonNullable<LoginMitarbeiterListQuery['getOwnMitarbeiterList']['data'][0]>;
export type LoginMitarbeiterListFirmendatenData = NonNullable<LoginMitarbeiterListQuery['getOwnMitarbeiterList']['data'][0]>['firmendatenData'];
export type LoginMitarbeiterListErrorList = NonNullable<LoginMitarbeiterListQuery['getOwnMitarbeiterList']['errorList'][0]>;
export type LoginMitarbeiterListWarningList = NonNullable<LoginMitarbeiterListQuery['getOwnMitarbeiterList']['warningList'][0]>;
export const useLoginMitarbeiterList = useLoginMitarbeiterListQuery;
export type MitarbeiterMeVariables = MitarbeiterMeQueryVariables;
export type MitarbeiterMeGetMitarbeiterMe = MitarbeiterMeQuery['getMitarbeiterMe'];
export type MitarbeiterMeData = MitarbeiterMeQuery['getMitarbeiterMe']['data'];
export type MitarbeiterMeRolleList = NonNullable<MitarbeiterMeQuery['getMitarbeiterMe']['data']['rolleList'][0]>;
export const useMitarbeiterMe = useMitarbeiterMeQuery;

export const MeDocument = gql`
  query Me {
    getMe {
      data {
        userId
        vorname
        nachname
        email
        role
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
  return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
}
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const LoginMitarbeiterListDocument = gql`
  query LoginMitarbeiterList {
    getOwnMitarbeiterList {
      data {
        firmendatenData {
          firmendatenId
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useLoginMitarbeiterListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LoginMitarbeiterListQuery, LoginMitarbeiterListQueryVariables>
) {
  return ApolloReactHooks.useQuery<LoginMitarbeiterListQuery, LoginMitarbeiterListQueryVariables>(LoginMitarbeiterListDocument, baseOptions);
}
export function useLoginMitarbeiterListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoginMitarbeiterListQuery, LoginMitarbeiterListQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LoginMitarbeiterListQuery, LoginMitarbeiterListQueryVariables>(LoginMitarbeiterListDocument, baseOptions);
}
export type LoginMitarbeiterListQueryHookResult = ReturnType<typeof useLoginMitarbeiterListQuery>;
export type LoginMitarbeiterListLazyQueryHookResult = ReturnType<typeof useLoginMitarbeiterListLazyQuery>;
export type LoginMitarbeiterListQueryResult = ApolloReactCommon.QueryResult<LoginMitarbeiterListQuery, LoginMitarbeiterListQueryVariables>;
export const MitarbeiterMeDocument = gql`
  query MitarbeiterMe($firmendatenId: ID!) {
    getMitarbeiterMe(firmendatenId: $firmendatenId) {
      data {
        vorname
        nachname
        firmendatenId
        funktionsModulList
        mitarbeiterId
        rolleList {
          name
          rolleId
        }
      }
    }
  }
`;
export function useMitarbeiterMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MitarbeiterMeQuery, MitarbeiterMeQueryVariables>) {
  return ApolloReactHooks.useQuery<MitarbeiterMeQuery, MitarbeiterMeQueryVariables>(MitarbeiterMeDocument, baseOptions);
}
export function useMitarbeiterMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MitarbeiterMeQuery, MitarbeiterMeQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<MitarbeiterMeQuery, MitarbeiterMeQueryVariables>(MitarbeiterMeDocument, baseOptions);
}
export type MitarbeiterMeQueryHookResult = ReturnType<typeof useMitarbeiterMeQuery>;
export type MitarbeiterMeLazyQueryHookResult = ReturnType<typeof useMitarbeiterMeLazyQuery>;
export type MitarbeiterMeQueryResult = ApolloReactCommon.QueryResult<MitarbeiterMeQuery, MitarbeiterMeQueryVariables>;
