import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider, LocaleProvider } from 'baseui';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ErrorBoundary } from 'react-error-boundary';
import App from './App';
import { AuthProvider } from './features/Auth/auth-context';
import AuthApolloProvider from './graphql/AuthApolloProvider';
import theme from './theme';
import localeOverrideDe from './locale/localeOverrideDe';
import MainApolloProvider from './graphql/MainApolloProvider';
import { HVLogoProvider } from './features/HVLogo/HVLogoProvider';
import GeneralError from './features/Error/GeneralError';

const DevApp = lazy(() => import('./DevApp'));

const engine = new Styletron();
const history = createBrowserHistory();

const ProductionApp = () => <App />;

ReactDOM.render(
  <StyletronProvider value={engine}>
    <BaseProvider theme={theme}>
      <LocaleProvider locale={localeOverrideDe}>
        <Router history={history}>
          <ErrorBoundary fallbackRender={GeneralError}>
            <AuthApolloProvider>
              <AuthProvider>
                <MainApolloProvider>
                  <HVLogoProvider>
                    {process.env.NODE_ENV === 'production' ? (
                      <ProductionApp />
                    ) : (
                      <Suspense fallback={<></>}>
                        <DevApp />
                      </Suspense>
                    )}
                  </HVLogoProvider>
                </MainApolloProvider>
              </AuthProvider>
            </AuthApolloProvider>
          </ErrorBoundary>
        </Router>
      </LocaleProvider>
    </BaseProvider>
  </StyletronProvider>,
  document.getElementById('root')
);
