import React from 'react';
import { Flex, Box } from 'rebass';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import useBreakpoint from '../../helpers/layoutUtils';
import { useAuth } from '../../features/Auth/auth-context';

// fix scrollbar issue https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ => issue performance
// used a hack see breakpoint where I render a block on S
const Layout = (props: { header: React.ReactNode; mainContent: React.ReactNode; sidebar?: React.ReactNode; footer?: React.ReactNode }) => {
  const { header, mainContent, sidebar, footer, ...restProps } = props;
  const { user, isLoginDone } = useAuth();
  const breakpoint = useBreakpoint();
  const [, theme] = useStyletron();

  const isLoggedIn = isLoginDone && !!user;

  return (
    <Flex flexDirection="column" minHeight="100vh" {...restProps}>
      <Box as="header">{header}</Box>
      <Box as="main" p="16px" backgroundColor={theme.colors.primaryB} flex="1 1 auto">
        {mainContent}
        {breakpoint === 'S' && isLoggedIn && <Block height="calc(75px)" />}
      </Box>
    </Flex>
  );
};

export default Layout;
