import React, { useEffect, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToasterContainer } from 'baseui/toast';
import loadable, { lazy } from '@loadable/component';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router';
import { LogoutReason, useAuth } from './features/Auth/auth-context';
import * as ROUTES from './constants/routes';
import Layout from './components/Layout';
import LayoutLoading from './components/Layout/LayoutLoading';
import { useToggle } from './helpers/useToggle';
import useBreakpoint from './helpers/layoutUtils';
import MobileTabBar from './components/Mobile/MobileTabBar';
import GeneralError from './features/Error/GeneralError';
import AuthRoute from './security/AuthRoute';
import { isAdminOrHasHausverwaltungFm } from './security/permissionChecks';

const SignInPage = lazy(() => import('./pages/SignIn'));
const SignUpPage = lazy(() => import('./pages/SignUp'));
const ChooseFirmaPage = lazy(() => import('./pages/ChooseFirma'));
const PasswordForgetPage = lazy(() => import('./pages/PasswordForget/PasswordForgetPage'));
const PasswordResetPage = lazy(() => import('./pages/PasswordForget/PasswordResetPage'));

const MitarbeiterListPage = loadable(() => import('./pages/BenutzerPage'));
const ObjektListPage = loadable(() => import('./pages/ObjektListPage'));
const ObjektDetailsPage = loadable(() => import('./pages/ObjektDetailsPage'));
const DokumentListPage = loadable(() => import('./pages/DokumentListPage'));
const AccountPage = loadable(() => import('./pages/AccountPage'));
const ServicePage = loadable(() => import('./pages/ServicePage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));

const Header = loadable(() => import('./components/Header'));

const App = () => {
  const breakpoint = useBreakpoint();
  const { user, isLoginDone, activeForFirmendatenId, logoutReason } = useAuth();
  const [isOpen, toggle] = useToggle();
  const location = useLocation<LoginLocationState>();

  const isLoggedIn = isLoginDone && !!user;

  let header;
  switch (breakpoint) {
    case 'XL':
    case 'LG':
    case 'MD':
      header = <Header isLoggedIn={isLoggedIn} onOpen={toggle} />;
      break;
    case 'S': // Native TabBar
      header = <MobileTabBar isLoggedIn={isLoggedIn} onOpen={toggle} isOpen={isOpen} />;
      break;
    default:
      header = <MobileTabBar isLoggedIn={isLoggedIn} onOpen={toggle} isOpen={isOpen} />;
  }

  useEffect(() => {
    if (!isLoggedIn) {
      toggle(false);
    }
  }, [isLoggedIn, toggle]);

  return (
    <ToasterContainer
      placement="topRight"
      autoHideDuration={2000}
      overrides={{
        ToastCloseIcon: {
          style: () => ({
            display: 'none',
          }),
        },
      }}
    >
      <Layout
        // footer={<div>Hello World</div>}
        header={header}
        mainContent={
          <div>
            <ErrorBoundary fallbackRender={GeneralError}>
              <Suspense fallback={<LayoutLoading />}>
                {!isLoginDone ? (
                  <Switch>
                    <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                    <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
                    <Route path={ROUTES.SIGN_IN_CHOOSE_FIRMA} component={ChooseFirmaPage} />
                    <Route path={ROUTES.PASSWORD_RESET} component={PasswordResetPage} />
                    <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
                    <Route path={ROUTES.ERROR} component={ErrorPage} />
                    <Route render={({ location }) => <Redirect to={{ pathname: ROUTES.SIGN_IN, state: { from: location.pathname } }} />} />
                  </Switch>
                ) : (
                  <>
                    {activeForFirmendatenId ? (
                      <Switch>
                        <Route path={ROUTES.OBJEKT_DETAILS} component={ObjektDetailsPage} />
                        <Route exact path={ROUTES.OBJEKT_LIST} component={ObjektListPage} />
                        <Route exact path={ROUTES.LANDING} component={ObjektListPage} />
                        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                        <AuthRoute
                          path={ROUTES.SERVICE}
                          component={ServicePage}
                          hasPermissionFn={(permissionInfo) => !isAdminOrHasHausverwaltungFm(permissionInfo)}
                        />
                        <Route path={ROUTES.DOKUMENTE} component={DokumentListPage} />
                        <AuthRoute path={ROUTES.BENUTZER} component={MitarbeiterListPage} hasPermissionFn={isAdminOrHasHausverwaltungFm} />
                        <Route path={ROUTES.ERROR} component={ErrorPage} />
                        <Route render={() => <Redirect to={{ pathname: getOriginalFromPathOrDefault(logoutReason, location.state?.from) }} />} />
                      </Switch>
                    ) : (
                      // for androadmin without selected firma
                      <Switch>
                        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                        <Route render={() => <Redirect to={{ pathname: getOriginalFromPathOrDefault(logoutReason, location.state?.from) }} />} />
                      </Switch>
                    )}
                  </>
                )}
              </Suspense>
            </ErrorBoundary>
          </div>
        }
      />
    </ToasterContainer>
  );
};

type LoginLocationState = { from: string } | null | undefined;

const getOriginalFromPathOrDefault = (logoutReason?: LogoutReason, fromPath?: string) => {
  return logoutReason === 'unauthorized' && fromPath ? fromPath : ROUTES.LANDING;
};

export default App;
