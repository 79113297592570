export const LANDING = '/';
export const SIGN_UP = '/signup/:token';
export const SIGN_IN = '/signin';
export const SIGN_IN_CHOOSE_FIRMA = '/choose-company';
export const ACCOUNT = '/account';
export const SERVICE = '/service';
export const SERVICE_CONTACT = `${SERVICE}/contact`;
export const PASSWORD_FORGET = '/password-reset';
export const PASSWORD_RESET = `${PASSWORD_FORGET}/:token`;
export const OBJEKT_LIST = '/objects';
export const OBJEKT_DETAILS = `${OBJEKT_LIST}/:objektId`;
export const DOKUMENTE = '/documents';
export const BENUTZER = '/users';
export const ERROR = '/404';
