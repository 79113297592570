import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Button } from 'baseui/button';
import { LabelXSmall } from 'baseui/typography';
import React from 'react';

type MobileTabButtonProps = {
  route: string;
  isSelected: boolean | undefined;
  icon: React.ReactNode;
  label: string;
};

const MobileTabButton: React.FC<MobileTabButtonProps> = ({ route, isSelected, icon, label }) => {
  return (
    <LabelXSmall display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Link style={{ textDecoration: 'none' }} to={route}>
        <motion.div whileTap={{ scale: 1.05 }}>
          <Button
            overrides={{
              BaseButton: {
                style: ({ $theme, $isSelected }) => {
                  if ($isSelected)
                    return {
                      backgroundColor: $theme.colors.accent50,
                    };
                  return {};
                },
              },
            }}
            isSelected={isSelected}
            type="button"
            aria-label={label}
            kind="tertiary"
            shape="round"
            size="compact"
          >
            {icon}
          </Button>
        </motion.div>
      </Link>
      {label}
    </LabelXSmall>
  );
};

export default MobileTabButton;
