import { useAuth } from './auth-context';
import { isAdminOrHasHausverwaltungFm, isAdmin } from '../../security/permissionChecks';

const usePersmission = () => {
  const { mitarbeiter, user, activeForFirmendatenId } = useAuth();
  const role = user?.role;
  const funktionsModulList = mitarbeiter?.funktionsModulList;

  const isAdminOrHausverwalter = isAdminOrHasHausverwaltungFm({ role, funktionsModulList });

  return {
    isAdminOrHausverwalter,
    isAdmin: isAdmin({ role, funktionsModulList }),
    isFirmendatenSelected: !!activeForFirmendatenId,
  };
};

export default usePersmission;
